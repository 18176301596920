import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';
import 'react-phone-number-input/style.css';
import './CreateJob.scss';
import SimpleReactValidator from 'simple-react-validator';
import { CreateDashboard } from '../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
  },
};
const gender_options = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];
const CreateJob = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [documentnumber, setDocumentnumber] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const phoneInput = useRef(null);
  const pancardInput = useRef(null);
  const emailInput = useRef(null);
  const dobInput = useRef(null);
  const genderInput = useRef(null);

  const handleSubmit = (event) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
    if (formValid) {
      let data = {
        "first_name": firstname,
        "last_name": lastname,
        "phone": phone,
        "date_of_birth": dob,
        "pan_card": documentnumber,
        "gender": gender.value
      }
      setDisableSubmit(true);
      CreateDashboard(data).then(response => {
        props.closeModal();
        window.location.reload(false);
      });

    }

    event.preventDefault();
  }

  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        ariaHideApp={false}
        contentLabel="Create Job"
        style={customStyles}

      >
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg="2"></Col>
            <Col lg="4">
              <Form.Group className="mb-3">
                <Form.Control name="firstname" value={firstname} type="text"
                  ref={firstNameInput}
                  placeholder="First Name" onChange={(e) => setFirstname(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      lastNameInput.current.focus();
                      e.preventDefault();
                    }
                  }}
                />
                <div className='mt-2'>
                  {simpleValidator.current.message('firstname', firstname, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select name="gender" value={gender} options={gender_options}
                  onChange={(e) => { setGender(e); }} ref={genderInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      phoneInput.current.focus();
                      e.preventDefault();
                    }
                  }}
                />
                <div className='mt-2'>
                  {simpleValidator.current.message('gender', gender, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="documentnumber" value={documentnumber} type="text"
                  placeholder="PAN Card" ref={pancardInput} onChange={(e) => setDocumentnumber(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      firstNameInput.current.focus();
                      e.preventDefault();
                    }
                  }}
                />
                <div className='mt-2'>
                  {simpleValidator.current.message('documentnumber', documentnumber, 'required')}
                </div>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group className="mb-3">
                <Form.Control name="lastname" value={lastname} type="text"
                  ref={lastNameInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      genderInput.current.focus();
                      e.preventDefault();
                    }
                  }}
                  placeholder="Last Name" onChange={(e) => setLastname(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('lastname', lastname, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" name="phone" value={phone}
                  ref={phoneInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      pancardInput.current.focus();
                      e.preventDefault();
                    }
                  }}
                  placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('phone', phone, "required|numeric|between:10,10,string")}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="dob" value={dob} type="date"
                  ref={dobInput}
                  onKeyDown={(e) => {

                  }}
                  placeholder="DOB" onChange={(e) => setDob(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('dob', dob, 'required')}
                </div>
              </Form.Group>
            </Col>
            <Col lg="2"></Col>
          </Row>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8" className='text-center'>
              <Button type="submit" variant="primary" disable={disableSubmit}>Submit</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button onClick={props.closeModal} variant="secondary">Close</Button>
            </Col>
            <Col lg="2"></Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default CreateJob;