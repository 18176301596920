import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar as ReactNavbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuthenticator } from '@aws-amplify/ui-react'
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navbar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const LogOut = () => {
    signOut();
  }
  return (
    <>
      <ReactNavbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <ReactNavbar.Brand href="">AMSScoring</ReactNavbar.Brand>
          <ReactNavbar.Toggle aria-controls="basic-navbar-nav" />
          <ReactNavbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title={user.attributes.email} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={LogOut}>Signout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </ReactNavbar.Collapse>
        </Container>
      </ReactNavbar>
    </>
  )
}
export default Navbar;