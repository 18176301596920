import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './js/Routes';
import { AmplifyProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import config from './../src/js/config/config.json';

Auth.configure({
  Auth: {
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.USER_POOL_APP_CLIENT_ID,

  },
  initialState: 'signUp',
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AmplifyProvider >
    <Routes />
  </AmplifyProvider>
);
