import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
  },
};

const ListResponse = (props) => {
  useEffect(() => {
    console.log(props.responseModalIsOpen);
  }, [])
  useEffect(() => {
  }, [props.responseModalIsOpen]);
  return (
    <>
      <Modal
        isOpen={props.responseModalIsOpen}
        ariaHideApp={false}
        contentLabel="Create Job"
        style={customStyles}

      >
        <Row>
          <Col lg="2"></Col>
          <Col lg="8" className='text-center'>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={props.closeModal} variant="secondary">Close</Button>
          </Col>
          <Col lg="2"></Col>
        </Row>
      </Modal>
    </>
  );
}

export default ListResponse;