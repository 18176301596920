import React, { } from "react";
import { BrowserRouter as Router, Routes as ReactRoutes, Route } from "react-router-dom";
import { Container as ReactContainer } from 'react-bootstrap';
import Navbar from "./components/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import { withAuthenticator } from '@aws-amplify/ui-react'

const Routes = (props) => {
  return (
    <>
      <ReactContainer fluid className="p-0">
        <Navbar></Navbar>
        <Router>
          <ReactRoutes>
            <Route path="/" element={<Dashboard />} />
          </ReactRoutes>
        </Router>
      </ReactContainer>
    </>
  );
}
export default withAuthenticator(Routes, { hideSignUp: true });