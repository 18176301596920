import React, { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import CreateJob from '../createjob/CreateJob';
import { DashboardData } from '../../api';
import ListResponse from '../listresponse/ListResponse';
import config from '../../config/config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Dashboard.scss';

const Dashboard = () => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [search, setSearch] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRecordPhone, setDeleteRecordPhone] = useState('');
  const [deleteRecordDate, setDeleteRecordDate] = useState('');
  const [dashboardData, setDashboardData] = useState([]);
  const [lastSearch, setLastSearch] = useState({
    Date: {
      N: "1685581260"
    },
    Year: {
      N: "2023"
    }
  });
  const [showNextDisable, setShowNextDisable] = useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [responseModalIsOpen, setResponseModalIsOpen] = React.useState(false);
  const nextSearch = () => {
    console.log("A");
    DashboardData({
      "date": lastSearch.Date.N,
      "year": lastSearch.Year.N
    }).then(response => {
      if (response.LastSearch === null) {
        let currentResponseData = response.ResponseData;
        let finalResponseData = currentResponseData.concat(dashboardData);
        setDashboardData(finalResponseData);
        setShowNextDisable(true);
      } else {
        let currentResponseData = response.ResponseData;
        let finalResponseData = currentResponseData.concat(dashboardData);
        setDashboardData(finalResponseData);
        setLastSearch(response.LastSearch);
      }

    })
  }

  useEffect(() => {

    DashboardData({
      "date": lastSearch.Date.N,
      "year": lastSearch.Year.N,
      "search": search,
    }).then(response => {
      setDashboardData(response.ResponseData);
      if (response.LastSearch === null) {
      } else {
        setLastSearch(response.LastSearch);
      }
    })

  }, [search])

  function getToken() {

    for (var i = 0; i < localStorage.length; i++) {

      var key = localStorage.key(i);
      var value = localStorage.getItem(key);
      if (key.match(/idToken.*/)) {

        return value;
      }
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setResponseModalIsOpen(false);
    setShowDeleteModal(false);
    setSearch('');
    setDeleteRecordPhone('');
    setDeleteRecordDate('');
  }
  const createModal = () => {
    setModalIsOpen(modalIsOpen => !modalIsOpen);
  }
  const DeleteRecordExecute = () => {
    let token = getToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Encoding", "gzip, deflate, br")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "date": deleteRecordDate,
        "phone": deleteRecordPhone,
      })
    }
    fetch(config.BASE_URL + "record/delete", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result === 'true') {
          setShowDeleteModal(false);
          window.location.reload(false);
        }
      })
      .catch(error => console.log('error', error));
  }
  const DeleteRecord = (phone, date) => {
    setDeleteRecordDate(date);
    setDeleteRecordPhone(phone);
    setShowDeleteModal(true);
  }
  const GetDashboardDownload = (phone, date) => {
    let token = getToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Encoding", "gzip, deflate, br")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "date": date,
        "phone": phone,
      })
    }
    fetch(config.BASE_URL + "pdf/download", requestOptions)
      .then(response => response.blob())
      .then(result => {
        var reader = new FileReader();
        reader.onload = function () {
          var base64data = reader.result;
          const linkSource = `data:application/pdf;base64,${base64data}`;
          const downloadLink = document.createElement('a');
          document.body.appendChild(downloadLink);
          downloadLink.href = linkSource;
          downloadLink.target = '_self';
          downloadLink.download = 'report.pdf';
          downloadLink.click();
        }
        reader.readAsText(result);
      })
      .catch(error => console.log('error', error));
  }
  return (
    <>  
      <Modal
        isOpen={showDeleteModal}
        style={customStyles}
        contentLabel="Delete Record"
      >
        <Row>
          <Col lg="12" className='text-center'>
            Are you sure you want to delete this record ?
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col lg="12" className='text-center'>
            <Button onClick={DeleteRecordExecute} type="button" variant="primary">Delete</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={closeModal} variant="secondary">Close</Button>
          </Col>
        </Row>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '40px' }}>
        <Button variant="primary" onClick={createModal}>Create</Button>
      </div>
      <ListResponse
        responseModalIsOpen={responseModalIsOpen}
        closeModal={closeModal}
      >
      </ListResponse>
      <CreateJob
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      >
      </CreateJob>
      <Container >
        <input type="text" value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)} className='form-control mb-4'></input>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dashboardData.map((value, key) => (
              <tr key={key}>
                <td>{value.FirstName}</td>
                <td>{value.LastName}</td>
                <td>{value.Phone}</td>
                <td className="download" >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a onClick={() => GetDashboardDownload(value.Phone, value.Date)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a onClick={() => DeleteRecord(value.Phone, value.Date)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '40px' }}>
          <Button variant="primary" disabled={showNextDisable} onClick={nextSearch}>Next</Button>
        </div>

      </Container>


    </>
  );
}

export default Dashboard;