/* eslint-disable no-console */
import axios from 'axios';
import config from '../config/config.json';

function getToken() {

  for (var i = 0; i < localStorage.length; i++) {

    var key = localStorage.key(i);
    var value = localStorage.getItem(key);
    if (key.match(/idToken.*/)) {

      return value;
    }
  }
};
const api = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  let token = getToken();
  config.headers.Authorization = token;
  return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const generic_error = 'Something went wrong.';
    if (error.response && error.response.data) {
      if (typeof error.response.data === 'string') {
        const newData = {
          error: generic_error,
          info: error.response.data,
        };
        return Promise.reject(newData);
      }
      return Promise.reject(error.response);
    }
    if (error.message === 'Network Error') {
      error.message = 'Network Error';
    }
    return Promise.reject(error.message);
  },
);

export default api;